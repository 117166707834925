// src/App.js
import React from 'react';
import Reservation from './components/Reservation';
import './styles.css';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>予約ページ - パーソナルジム かたぎり塾 肥後橋店</title>
        <meta name="description" content="パーソナルジムかたぎり塾肥後橋店の予約ページです。" />
      </Helmet>
      <Reservation />
    </div>
  );
}

export default App;
