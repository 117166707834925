// src/components/Reservations.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css';
import { Table, Form, Button, Input, Label, Container } from 'semantic-ui-react';


const Reservations = () => {
    const [data, setData] = useState({
      id: 22,
      name: '肥後橋店',
      slug: 'higobashi',
      shopOpenings: [
        {
          day: 'WEDNESDAY',
          beginTime: '08:00:00+09',
          endTime: '22:00:00+09'
        },
        {
          day: 'THURSDAY',
          beginTime: '08:00:00+09',
          endTime: '22:00:00+09'
        },
        { day: 'FRIDAY', beginTime: '08:00:00+09', endTime: '22:00:00+09' },
        {
          day: 'SATURDAY',
          beginTime: '08:00:00+09',
          endTime: '21:00:00+09'
        }
      ],
      rsvs: [
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-06T16:00:00+09:00',
          endAt: '2023-09-06T17:00:00+09:00'
        },
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-20T16:00:00+09:00',
          endAt: '2023-09-20T17:00:00+09:00'
        },
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-20T19:00:00+09:00',
          endAt: '2023-09-20T20:00:00+09:00'
        },
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-27T19:00:00+09:00',
          endAt: '2023-09-27T20:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-01T18:00:00+09:00',
          endAt: '2023-09-01T19:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-08T18:00:00+09:00',
          endAt: '2023-09-08T19:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-22T18:00:00+09:00',
          endAt: '2023-09-22T19:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-01T19:00:00+09:00',
          endAt: '2023-09-01T20:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-08T19:00:00+09:00',
          endAt: '2023-09-08T20:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-15T19:00:00+09:00',
          endAt: '2023-09-15T20:00:00+09:00'
        },
        {
          _day: 'FRIDAY',
          beginAt: '2023-09-22T19:00:00+09:00',
          endAt: '2023-09-22T20:00:00+09:00'
        },
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-13T18:00:00+09:00',
          endAt: '2023-09-13T19:00:00+09:00'
        },
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-20T18:00:00+09:00',
          endAt: '2023-09-20T19:00:00+09:00'
        },
        {
          _day: 'WEDNESDAY',
          beginAt: '2023-09-27T18:00:00+09:00',
          endAt: '2023-09-27T19:00:00+09:00'
        },
        {
          _day: 'SATURDAY',
          beginAt: '2023-09-02T10:00:00+09:00',
          endAt: '2023-09-02T11:00:00+09:00'
        },
        {
          _day: 'SATURDAY',
          beginAt: '2023-09-09T10:00:00+09:00',
          endAt: '2023-09-09T11:00:00+09:00'
        },
        {
          _day: 'SATURDAY',
          beginAt: '2023-09-16T10:00:00+09:00',
          endAt: '2023-09-16T11:00:00+09:00'
        },
        {
          _day: 'SUNDAY',
          beginAt: '2023-09-24T13:00:00+09:00',
          endAt: '2023-09-24T14:00:00+09:00'
        },
      ]
    });
    const [selectedDate, setSelectedDate] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        emailConfirm: '',
        beginAt: '',
        endAt: '',
    });
    const [headers, setHeaders] = useState([]);
    const [hours, setHours] = useState([]);
    const [startIndex, setStartIndex] = useState(0);

    function getTomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(tomorrow.getHours() + 9); // NOTE: 9時間加算してJSTに変換
      return tomorrow;
    }

    const getDayOfWeek = useCallback((date) => {
        const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        return daysOfWeek[date.getDay()];
    }, []);


    function getHourFromTime(time) {
      return parseInt(time.split(':')[0], 10);
    }

    function isReserved(hour, day, date, rsvs) {
      return rsvs.some(rsv => {
        const rsvBeginDate = new Date(rsv.beginAt);
        const rsvEndDate = new Date(rsv.endAt);
        const rsvBeginHour = rsvBeginDate.getHours();
        const rsvEndHour = rsvEndDate.getHours();
        rsvBeginDate.setHours(rsvBeginDate.getHours() + 9);
        const rsvDate = rsvBeginDate.toISOString().split('T')[0];

        return hour >= rsvBeginHour && hour < rsvEndHour && rsv._day === day && rsvDate === date;
      });
    }


    const handleLeftClick = () => {
      if (startIndex > 0) {
        setStartIndex(prevIndex => prevIndex - 1);
      }
    };

    const handleRightClick = () => {
      if (startIndex < headers.length - 1) {
        setStartIndex(prevIndex => prevIndex + 1);
      }
    };

    useEffect(() => {
      // Netlify Functionを呼び出す
      axios.get('/.netlify/functions/getReservations')
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.log("ERROR")
        });
      const tomorrow = getTomorrow();
        const newHeaders = Array.from({ length: 7 }).map((_, i) => {
            const date = new Date(tomorrow);
            date.setDate(date.getDate() + i);
            date.setHours(date.getHours() + 9);
            return {
                day: getDayOfWeek(date),
                date: date.toISOString().split('T')[0],
            };
        });
        setHeaders(newHeaders);

        const newHours = [];
        for (let i = 8; i <= 21; i++) {
            newHours.push(i);
        }
        setHours(newHours);
    }, [getDayOfWeek]);

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setShowForm(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    function convertDayToJapanese(day) {
      const daysInJapanese = {
        'SUNDAY': '日',
        'MONDAY': '月',
        'TUESDAY': '火',
        'WEDNESDAY': '水',
        'THURSDAY': '木',
        'FRIDAY': '金',
        'SATURDAY': '土',
      };
      return daysInJapanese[day] || day;
    }

    const formSubmit = useCallback(() => {
        let data = formData;
        let [datePart, timePart] = selectedDate.split(' ');

        // 時間部分を2桁に変換
        let [hour, minute] = timePart.split(':');
        hour = hour.padStart(2, '0');
        timePart = `${hour}:${minute}`;

        data.beginAt = `${datePart}T${timePart}:00`;

        // selectedDateを解釈し、1時間を加算
        let endAt = new Date(`${datePart}T${timePart}:00`);
        endAt.setHours(endAt.getHours() + 1);

        // toISOStringを使用せずに日時をフォーマット
        const formattedEndAt = `${endAt.getFullYear()}-${String(endAt.getMonth() + 1).padStart(2, '0')}-${String(endAt.getDate()).padStart(2, '0')}T${String(endAt.getHours()).padStart(2, '0')}:${String(endAt.getMinutes()).padStart(2, '0')}:${String(endAt.getSeconds()).padStart(2, '0')}`;
        data.endAt = formattedEndAt;

        const functionUrl = '/.netlify/functions/createReservation';

        // requestBodyの作成
        const requestBody = {
            query: `
            mutation MyMutation($input: CreateRsvByGuestInput!) {
              createRsvByGuest(input: $input) {
                rsvId
              }
            }`,
            variables: {
                input: {
                    shopId: 22,
                    beginAt: data.beginAt,
                    endAt: data.endAt,
                    email: data.email,
                    fullName: data.name, // nameをfullNameにマッピング
                    phoneNumber: data.phone,
                    referrerUrl: "https://www.google.com/"
                }
            }
          };

          // POSTリクエストの送信
          axios.post(functionUrl, requestBody, {
              headers: {
                  'Content-Type': 'application/json',
              },
          })
          .then(response => {
              console.log(response.data);
              // response.dataが存在する場合は予約完了のアラートを出してトップページに遷移
              if (response.data) {
                  alert('予約が完了しました。');
                  window.location.href = 'https://higobashi-personal-gym.jp';
              }
          })
          .catch(error => {
              console.error('Error:', error);
              // response.dataが存在しない場合はエラーのアラートを出す
              alert('予約に失敗しました。もう一度やり直してください。');
          });
    }, [formData, selectedDate]);





    return (
    <Container className="center-container">
        <h2 className="ui header">無料体験の予約受付</h2>
        {!showForm && (
        <div>
          <Table celled textAlign="center" className='rsv-table'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Button onClick={handleLeftClick}>&lt; 前日</Button>
                  <Button onClick={handleRightClick}>翌日 &gt;</Button>
                </Table.HeaderCell>
                {headers.slice(startIndex, startIndex + 1).map(header => (
                  <Table.HeaderCell key={header.date} className={header.day === 'SATURDAY' ? 'sat' : header.day === 'SUNDAY' ? 'sun' : ''}>
                    {header.date.substring(5, 7).replace(/^0/, '') + '月' + header.date.substring(8).replace(/^0/, '') + "日"} ({convertDayToJapanese(header.day)})
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {hours.map(hour => (
                  <Table.Row key={hour}>
                      <Table.Cell>{hour}:00</Table.Cell>
                      {headers.slice(startIndex, startIndex + 1).map(header => {
                          const isShopOpen = data?.shopOpenings?.some(shop => {
                            return shop.day === header.day && getHourFromTime(shop.beginTime) <= hour && hour < getHourFromTime(shop.endTime);
                          });
                          const reserved = isReserved(hour, header.day, header.date, data?.rsvs);
                          let cellClass = '';
                          let cellContent = '';
                          let cellOnClick = null;

                          if (isShopOpen) {
                              if (reserved) {
                                  cellClass = 'cross';
                                  cellContent = '×';
                              } else {
                                  cellClass = 'circle';
                                  cellContent = '◯';
                                  cellOnClick = () => handleDateSelect(`${header.date} ${hour}:00`);
                              }
                          } else {
                              cellClass = 'no-reservation';
                              cellContent = '-';
                          }

                          return (
                              <Table.Cell key={header.date} className={cellClass} onClick={cellOnClick}>
                                  {cellContent}
                              </Table.Cell>
                          );
                      })}
                  </Table.Row>
              ))}
          </Table.Body>
        </Table>
        </div>
        )}
        {showForm && (
            <Form>
                <Form.Field>
                    <Label>予約日時</Label>
                    <Input type="text" name="date" value={selectedDate} required />
                </Form.Field>
                <Form.Field>
                    <Label>氏名</Label>
                    <Input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
                </Form.Field>
                <Form.Field>
                    <Label>電話番号</Label>
                    <Input type="tel" name="phone" value={formData.phone} onChange={handleInputChange} required />
                </Form.Field>
                <Form.Field>
                    <Label>メールアドレス</Label>
                    <Input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
                </Form.Field>
                <Form.Field>
                    <Label>メールアドレス（確認用）</Label>
                    <Input type="email" name="emailConfirm" value={formData.emailConfirm} onChange={handleInputChange} required />
                </Form.Field>

                <Button onClick={() => setShowForm(false)} className='black'>日時を再選択する</Button>
                <Button onClick={() => formSubmit()} className='orange' disabled={!(formData.name && formData.phone && formData.email && formData.email === formData.emailConfirm)}>無料で体験を予約する</Button>
                <br/>
                <br/>
                <p style={{color: "red", fontSize: 8}}>＊ メールアドレスを間違えると予約確定メールが届かないためご注意下さい。</p>
                <p style={{color: "red", fontSize: 8}}>＊ 広告の効果測定のため、第三者の運営するツールからかたぎり塾webページの誘導元となった広告の情報（クリック日や広告掲載サイトなど）を取得し、かたぎり塾サービスのご利用情報と照合する場合がございます。</p>
                <p style={{color: "red", fontSize: 8}}>＊ フォームの送信とともに<a href="https://katagirijuku.jp/pp/" rel="nofollow">プライバシーポリシー</a>に同意したものとします。</p>
                <p style={{color: "red", fontSize: 8}}>＊ 同業者の方の見学・視察が発覚した場合は、損害賠償金を請求させて頂きます。</p>
            </Form>
        )}
    </Container>
    );
};

export default Reservations;
